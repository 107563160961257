import React, { Component } from "react"
import NodeBlock from "./node-block"

export default class NodeTree extends Component {
  constructor(props) {
    super(props)
    this.nodeCounter = 0
    this.nodeArray = []

    this.totalSections = 1
    this.totalApproach = 3
    this.totalServices = 3
  }

  display() {
    return (
      <div className="node-wrapper-1">
        <div className="node-wrapper-2">
          <div className="node-tree no-display-tablet no-display-mobile">
            <div className="no-display">{this.nodeCounter++}</div>
            <div className="node-tree__block-1">
              <div className="node-top">
                <h1 className="node-align">o</h1>
                <div className="node-circle-small top-circle">
                  <div className="inner"></div>
                  {/*<?php get_template_part('template-parts/circ-path'); ?>*/}
                </div>
              </div>
              <div className="node-line">
                <div id="l-o-1" className="line-overlay"></div>
              </div>
              <a href="#">
                <div>
                  <div className="node-line-hor"></div>
                  <div id="node-cl-1" className="node-circle-large">
                    <div className="inner"></div>
                    <svg className="">
                      <defs>
                        <clipPath id="clipCircle">
                          <circle cx="30" cy="30" r="30" />
                        </clipPath>
                        <clipPath id="clipArc">
                          <path id="arcPath" d="" />
                        </clipPath>
                      </defs>
                      {/*
                      <g clip-path="url(#clipCircle)">
                        <image
                          xlink:href="<?php echo site_url('/wp-content/uploads/2022/03/Black.jpg'); ?>"
                          clip-path="url(#clipArc)"
                        />
                      </g>
                      */}
                    </svg>
                  </div>
                </div>
              </a>
            </div>

            <div className="no-display">{this.nodeCounter++}</div>
            <div className="node-tree__block-2">
              <div className="node-line">
                <div id="l-o-2" className="line-overlay"></div>
              </div>
              <div id="node-cs-1" className="node-circle-small">
                {/*<?php get_template_part('template-parts/circ-path'); ?>*/}
              </div>
            </div>
            <div className="no-display">{this.nodeCounter++}</div>
            <div className="node-tree__block-3">
              <div className="node-line">
                <div id="l-o-3" className="line-overlay"></div>
              </div>
              <div className="node-circle-small">
                {/*<?php get_template_part('template-parts/circ-path'); ?>*/}
              </div>
            </div>
            <div className="no-display">{this.nodeCounter++}</div>
            <div className="node-tree__block-4">
              <div className="node-line">
                <div id="l-o-4" className="line-overlay"></div>
              </div>
              <div className="node-circle-small">
                {/*<?php get_template_part('template-parts/circ-path'); ?>*/}
              </div>
            </div>
            <div className="no-display">{this.nodeCounter++}</div>
            <div className="node-tree__block-5">
              <div className="node-line">
                <div id="l-o-5" className="line-overlay"></div>
              </div>
              <a href="#">
                <div className="node-line-hor"></div>
                <div className="node-circle-large">
                  <div className="inner"></div>
                  {/*<?php get_template_part('template-parts/circ-path'); ?>*/}
                </div>
              </a>
            </div>

            <p id="totalSections" className="no-display">
              {this.totalSections}
            </p>
            <p id="totalServices" className="no-display">
              {this.totalApproach}
            </p>
            <p id="totalProducts" className="no-display">
              {this.totalServices}
            </p>

            {/* Generate nodes from posts */}
            {/* Immediately Invoked Function Expression (IIFE) */}
            {(() => {
              for (let i = 0; i < this.totalApproach + 1; i++) {
                let nodeBlock = new NodeBlock()

                this.nodeCounter++
                nodeBlock.nodeBlock =
                  "node-tree__block-" + this.nodeCounter.toString()
                nodeBlock.nodeLine = "l-o-" + this.nodeCounter.toString()
                nodeBlock.html1 = nodeBlock.block1()
                nodeBlock.html2 = nodeBlock.block2()

                if (i == this.totalApproach) {
                  this.nodeArray.push(nodeBlock.html1)
                } else {
                  this.nodeArray.push(nodeBlock.html2)
                }
              }
            })()}

            {this.nodeArray.map((nodeCurrent, i) => {
              return (
                <div className="node-tree-auto" key={i.toString()}>
                  {this.nodeArray[i]}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
