import React, { Component } from "react"

import * as PIXI from "pixi.js"
import $ from "jquery"

import Collision from "./collision"

export default class HeaderNode {
  constructor(type, refNum, xPos, yPos, velX, velY, centrePoint, numLines) {
    this.pixiDiv = null

    this.type = type
    this.refNum = refNum
    this.xPos = xPos
    this.yPos = yPos
    this.rad = null /* Radius */

    this.velX = velX
    this.velY = velY

    this.centrePoint = centrePoint
    this.numLines = numLines

    this.pixiObject = null
    this.pixiLine = []
    this.nodeSize = null

    this.alpha =
      this.randomIntFromInterval(6.5, 10) /
      10 /* this.alpha = (this.randomIntFromInterval(6.5, 10) / 10); */
  }

  setup() {
    this.pixiDiv = document.getElementById("pixi-container")
    this.capWidth = this.clamp(this.pixiDiv.offsetWidth, 0, 1680)

    let lineWidth
    if (this.type == "L2") {
      lineWidth = 3
    } else if (this.type == "L3") {
      lineWidth = 1
    }

    this.pixiObject = new PIXI.Graphics()
    this.pixiObject.lineStyle({
      color: 0xffffff,
      width: lineWidth,
      alignment: 0 /* 0.5 = middle, 1 = outer, 0 = inner */,
    })

    if (this.type == "L3") {
      this.pixiObject.lineStyle({
        alpha: this.alpha,
        color: 0xffffff,
        width: lineWidth,
        alignment: 0 /* 0.5 = middle, 1 = outer, 0 = inner */,
      })
    }

    if (this.type == "L2") {
      this.nodeSize = this.randomIntFromInterval(
        Math.ceil(this.capWidth / 67.2),
        Math.ceil(this.capWidth / 33.6)
      )
      /* L2 Numlines refers to the L3 connections. */
      for (let i = 0; i < this.numLines; i++) {
        let line = new PIXI.Graphics()
        line.lineStyle(0.5, 0xffffff)
        this.pixiLine.push(line)
      }
    } else if (this.type == "L3") {
      this.nodeSize = this.randomIntFromInterval(
        Math.ceil(this.capWidth / 336),
        Math.ceil(this.capWidth / 168)
      )
      /* L3 line refers to the central node connection. */
      let line = new PIXI.Graphics()
      line.lineStyle(0.5, 0xffffff)
      this.pixiLine.push(line)
    }

    this.rad = this.nodeSize / 2.0
  }

  draw(nodesL3, counter, firstFrame) {
    if (firstFrame) {
      this.pixiObject.beginFill(0x000000)
      this.pixiObject.drawCircle(0, 0, this.nodeSize)
      this.pixiObject.endFill()
    }

    this.pixiObject.position.set(
      this.centrePoint[0] + this.xPos,
      this.centrePoint[1] + this.yPos
    )

    if (this.type == "L2") {
      for (let i = 0; i < this.pixiLine.length; i++) {
        this.joinParticles(this, this.xPos, this.yPos, nodesL3, this.capWidth)
      }
    } else if (this.type == "L3") {
      /* Centre lines */
      for (let i = 0; i < this.pixiLine.length; i++) {
        this.pixiLine[i].clear()
        if (i % 3 == 0) {
          this.pixiLine[i].lineStyle(0.5, 0xffffff, this.alpha - 0.25)
          this.pixiLine[i].moveTo(
            this.centrePoint[0] + this.xPos,
            this.centrePoint[1] + this.yPos
          )
          this.pixiLine[i].lineTo(this.centrePoint[0], this.centrePoint[1])
        }
      }
    }
  }

  randomIntFromInterval(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  dist(x1, y1, x2, y2) {
    return Math.hypot(x2 - x1, y2 - y1)
  }

  /* Connect the closest 3 particles, irrespective of distance. */
  nodeSort(x, y, nodes) {
    let array = nodes
    let complete = false

    while (!complete) {
      complete = true
      for (let i = 1; i < array.length; i++) {
        let dist1 = this.dist(x, y, array[i - 1].xPos, array[i - 1].yPos)
        let dist2 = this.dist(x, y, array[i].xPos, array[i].yPos)
        if (dist1 > dist2) {
          complete = false
          let temp = array[i - 1]
          array[i - 1] = array[i]
          array[i] = temp
        }
      }
    }

    return array
  }

  /* Connect particles by distance. */
  joinParticles(currentNode, x, y, nodes, width) {
    let array = nodes
    let lineCount = 0
    let maxDist = width / 10

    for (let i = 0; i < currentNode.pixiLine.length; i++) {
      currentNode.pixiLine[i].clear()
    }

    for (let i = 0; i < array.length; i++) {
      let dist = this.dist(x, y, array[i].xPos, array[i].yPos)
      if (dist < maxDist && lineCount < currentNode.pixiLine.length) {
        currentNode.pixiLine[lineCount].lineStyle(0.5, 0xffffff)
        currentNode.pixiLine[lineCount].moveTo(
          currentNode.centrePoint[0] + currentNode.xPos,
          currentNode.centrePoint[1] + currentNode.yPos
        )
        currentNode.pixiLine[lineCount].lineTo(
          currentNode.centrePoint[0] + array[i].xPos,
          currentNode.centrePoint[1] + array[i].yPos
        )
        lineCount++
      }
    }
  }

  clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num
  }

  updatePos() {
    this.xPos += this.velX
    this.yPos += this.velY
  }
}
