import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulbOn } from "@fortawesome/pro-light-svg-icons"
import { faEye } from "@fortawesome/pro-regular-svg-icons"
import { faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons"

import Layout from "../components/layout"
import NodeTree from "../components/node-tree/node-tree"

import Casefile from "./services/casefile"
import RailGun from "./services/railgun"
import GateCrash from "./services/gatecrash"

// Pixi code
import PixiHeader from "../components/pixi/pixi-header"

const index = () => {
  let pixiHeader = new PixiHeader()
  let nodeTree = new NodeTree()

  let caseFile = new Casefile()
  let railGun = new RailGun()
  let gateCrash = new GateCrash()
  let serviceArray = [caseFile, railGun, gateCrash]

  if (typeof document !== `undefined`) {
    // initialise pixi
    pixiHeader.componentDidMount()

    window.serviceList = []

    for (let i = 0; i < serviceArray.length; i++) {
      window.serviceList.push(serviceArray[i].service.type.toLowerCase())
    }
  }

  // let urlArray = [
  //   "../assets/images/case-file-logo.png",
  //   "../assets/images/GateCrash.png",
  //   "../assets/images/railgun.png",
  // ]
  let url = ""

  let counter = 0

  let tiCurrentInt = null
  let tiCurrentStr = null

  return (
    <Layout>
      {/* Display node tree JSX */}
      {nodeTree.display()}

      {/* WP code -- passed to JS to decide whether to run PIXI */}
      <div id="runPIXI" className="no-display">
        1
      </div>
      {/* WP code -- passed to JS PIXI header -- seemingly unused */}
      <div id="imgURL" className="no-display">
        {/* REPLACE PHP */}
      </div>

      <div id="margins" className="no-display-tablet no-display-mobile">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div id="pixi-container" className="no-display-tablet no-display-mobile">
        <div className="gradient-container">
          <div className="gradient-top"></div>
          <div className="gradient-margin"></div>
          <div className="gradient-base"></div>
        </div>
      </div>

      {/* HEADER AREA */}
      <div className="ui-header-body-container">
        {/* CAROUSEL */}
        {(() => {
          var settings = {
            autoplay: true,
            arrows: false,
            speed: 1200,
            autoplaySpeed: 3600,
          }

          return (
            <Slider {...settings} className="header-desc-carousel">
              {/* REPLACE ALL MANUAL CODE CONVERTED FROM PHP */}
              <div id="slider-total-count-1" className="header-desc-container">
                <div className="underlay no-display-mobile no-display-tablet">
                  <h1 className="header-desc">More&nbsp;</h1>
                  <h1 className="header-desc">Sales&nbsp;</h1>
                  <h1 className="header-desc">,&nbsp;</h1>
                  <h1 className="header-desc">Greater&nbsp;</h1>
                  <h1 className="header-desc">Customer&nbsp;</h1>
                  <h1 className="header-desc">Insi</h1>
                  <h1 className="header-desc line-cover">g</h1>
                  <h1 className="header-desc">ht&nbsp;</h1>
                  <h1 className="header-desc">.&nbsp;</h1>
                </div>

                <div className="overlay no-display-mobile no-display-tablet">
                  <h1 className="header-desc ">More&nbsp;</h1>
                  <h1 className="header-desc underline-first-half underline-animate-first-half">
                    Sales
                  </h1>
                  <h1 className="header-desc ">,&nbsp;</h1>
                  <h1 className="header-desc ">Greater&nbsp;</h1>
                  <h1 className="header-desc ">Customer&nbsp;</h1>
                  <h1 className="header-desc underline-second-half underline-animate-second-half">
                    Insight
                  </h1>
                  <h1 className="header-desc ">.</h1>
                </div>

                <div className="mobile-header-text no-display-desktop">
                  <h1 className="header-desc">More&nbsp;</h1>{" "}
                  <h1 className="underline underline-animate">Sales,</h1>{" "}
                  <h1>&nbsp;Greater</h1> <h1>Customer&nbsp;</h1>{" "}
                  <h1 className="underline underline-animate"> Insight.</h1>{" "}
                </div>

                <div className="mobile-header-text no-display-desktop">
                  {/* REPLACE PHP */}
                </div>
              </div>

              <div id="slider-total-count-2" className="header-visitor-info">
                <h1 id="visitor-city">Visitor City</h1>
                <h1 id="visitor-country">Visitor Country</h1>
                <h1 id="visitor-post">Visitor PostCode</h1>
              </div>
            </Slider>
          )
        })()}

        <div className="header-scroller">
          <h6 className="header-scroller-1">01</h6>
          <div className="header-scroller-2"></div>
          <h6 className="header-scroller-3">02</h6>
        </div>

        <div className="button-container">
          <a href="#" className="">
            <div className="button">
              <div className="button-strike"></div>
              <h6 id="learn-more" className="button-text">
                LEARN MORE
              </h6>
            </div>
          </a>
        </div>
      </div>

      {/* SECTION - APPROACH */}
      <div id="ui-body-1" className="ui-body-container reverse home">
        <div id="approach-scroll" className="scroll-point"></div>
        <p className="section">— Approach</p>
        <h1 id="body-title-1" className="body-title body-title-1 scroll-fade">
          We provide data-driven reports on companies, people and events that
          redefine prospecting and account-based marketing.
        </h1>
        <div className="spacing-divider--small"></div>
        <div id="text-image-1" className="ui-body__text-image scroll-fade">
          <div className="ui-body__text-image--left">
            <h1 className="body-title">
              We Don’t Guess, <br></br>We Know.
            </h1>
            <div className="spacing-divider--small no-display-desktop">
              <div className="no-display-desktop divider-white"></div>
            </div>
            <p>
              Our <strong className="strong-white">data-driven</strong> pattern
              analysis provides{" "}
              <strong className="strong-white">targeted visibility</strong> in
              vivid depth. We use{" "}
              <strong className="strong-white">custom algorithms</strong> and
              cutting-edge processes to{" "}
              <strong className="strong-white">
                correlate open-source data
              </strong>{" "}
              for <strong className="strong-white">guaranteed accuracy</strong>.
            </p>
          </div>
          <div className="ui-body__text-image--right">
            <FontAwesomeIcon icon={faBullseyeArrow} className="icon" />
          </div>
        </div>

        <div
          id="text-image-2"
          className="ui-body__text-image spacing-vertical--large scroll-fade"
        >
          <div className="ui-body__text-image--left">
            <h1 className="body-title">Unparalleled Clarity.</h1>
            <div className="spacing-divider--small no-display-desktop">
              <div className="no-display-desktop divider-white"></div>
            </div>
            <p>
              IA data is based on{" "}
              <strong className="strong-white">real human interactions</strong>{" "}
              through an in-depth analysis of{" "}
              <strong className="strong-white">
                key companies, people and events
              </strong>
              .
            </p>
            <p>
              Conduct more{" "}
              <strong className="strong-white">efficient prospecting</strong>,
              we provide{" "}
              <strong className="strong-white">powerful insights</strong> to{" "}
              <strong className="strong-white">influence</strong> your{" "}
              <strong className="strong-white">decision making</strong> and{" "}
              <strong className="strong-white">
                streamline your messaging
              </strong>
              .
            </p>
          </div>
          <div className="ui-body__text-image--right">
            <FontAwesomeIcon icon={faEye} className="icon" />
          </div>
        </div>

        <div
          id="text-image-3"
          className="ui-body__text-image spacing-vertical--large scroll-fade"
        >
          <div className="ui-body__text-image--left">
            <h1 className="body-title">The Intelligent Choice.</h1>
            <div className="spacing-divider--small no-display-desktop">
              <div className="no-display-desktop divider-white"></div>
            </div>
            <p>
              IA{" "}
              <strong className="strong-white">eliminates the guesswork</strong>{" "}
              and accelerates the process of discovering{" "}
              <strong className="strong-white">key prospects</strong> and{" "}
              <strong className="strong-white">accounts</strong>.
            </p>
            <p>
              From intricacy to simplicity, we find{" "}
              <strong className="strong-white">hidden opportunities</strong> to
              bolster your{" "}
              <strong className="strong-white">
                sales and marketing strategies
              </strong>
              .
            </p>
          </div>
          <div className="ui-body__text-image--right">
            <FontAwesomeIcon icon={faLightbulbOn} className="icon" />
          </div>
        </div>
      </div>

      {/* SECTION - SERVICES */}
      <div id="ui-body-2" className="ui-body-container scroller home">
        <div id="services-scroll" className="scroll-point"></div>
        <p className="section">— Services</p>
        <h1 id="body-title-2" className="body-title body-title-2 scroll-fade">
          Our purpose-built solutions address real-world visibility issues in
          prospecting and sales.
        </h1>
        <div id="products-scroll" className="spacing-divider--small"></div>

        {serviceArray.map((serviceCurrent, i) => {
          if (i > 0) {
            serviceCurrent.service.class =
              serviceCurrent.service.class +
              " scroller " +
              "spacing-vertical--large"
          }

          if (i == 0) {
            url = "../assets/images/case-file-logo.png"
          } else if (i == 1) {
            url = "../assets/images/GateCrash.png"
          } else {
            url = "../assets/images/railgun.png"
          }

          tiCurrentInt = counter + nodeTree.totalServices + 1
          tiCurrentStr = "text-image-" + tiCurrentInt.toString()
          counter++

          return (
            <div key={i.toString()}>
              <div
                id={tiCurrentStr}
                className={serviceCurrent.service.class + " scroll-fade"}
              >
                <div className="ui-body__text-image--left">
                  <div className="ui-body__text-image--left-title-container">
                    <Link
                      to={serviceCurrent.service.url}
                      className="body-title"
                    >
                      <h1 className="body-title">
                        {serviceCurrent.service.type}
                      </h1>
                    </Link>
                    <Link to={serviceCurrent.service.url}>
                      <svg
                        className="svg-inline--fa fa-arrow-right-from-arc click-through"
                        data-prefix="fal"
                        data-icon="arrow-right-from-arc"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M256 480C264.8 480 272 487.2 272 496C272 504.8 264.8 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C264.8 0 272 7.164 272 16C272 24.84 264.8 32 256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480zM506.6 244C510 247.1 512 251.4 512 256C512 260.6 510 264.9 506.6 267.1L362.6 395.1C356 401.8 345.9 401.2 340 394.6C334.2 388 334.8 377.9 341.4 372L453.9 271.1H175.1C167.2 271.1 159.1 264.8 159.1 255.1C159.1 247.2 167.2 239.1 175.1 239.1H453.9L341.4 139.1C334.8 134.1 334.2 123.1 340 117.4C345.9 110.8 356 110.2 362.6 116L506.6 244z"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  {serviceCurrent.service.desc}
                </div>
                <Link
                  to={serviceCurrent.service.url}
                  className="ui-body__text-image--right ti-hover"
                >
                  <img
                    alt="services"
                    loading="lazy"
                    src={"" + serviceCurrent.service.featuredImg}
                    className={
                      "ui-body__text-image--img " +
                      serviceCurrent.service.fiOrientation
                    }
                  ></img>
                </Link>
              </div>
            </div>
          )
        })}
      </div>

      {/* SECTION - SUBSCRIPTIONS */}
      <div
        id="ui-body-3"
        className="ui-body-container ui-body-subscriptions centre home"
      >
        <div id="subsc-scroll" className="scroll-point"></div>
        <h1 id="body-title-3" className="body-title body-title-3 scroll-fade">
          Subscription Plans
        </h1>
        <div className="spacing-divider--small"></div>
        <div className="ui-body__subsc scroll-fade subsc-fade">
          <div id="subsc-alpha" className="subsc-box">
            <div className="subsc-line-overlay"></div>
            <h1 className="body-title">Alpha</h1>
            <div className="full-width-line">
              <div className="subsc-line-overlay-inner"></div>
            </div>
            <div className="spacing-divider--extra-small"></div>
            <h2>Monthly Access</h2>
            <p>
              <br></br>
            </p>
            <p>Access to any 'Alpha' class in development products.</p>
            <p>
              <br></br>
            </p>
            <p>60 Railguns.</p>
            <p>9 Case-Files.</p>
          </div>

          <div id="subsc-beta" className="subsc-box">
            <div className="subsc-line-overlay"></div>
            <h1 className="body-title">Beta</h1>
            <div className="full-width-line">
              <div className="subsc-line-overlay-inner"></div>
            </div>
            <div className="spacing-divider--extra-small"></div>
            <h2>Monthly Access</h2>
            <p>
              <br></br>
            </p>
            <p>Access to any 'Beta' class in development products.</p>
            <p>100 RailGuns.</p>
            <p>15 CaseFiles.</p>
            <p>1 GateCrash.</p>
          </div>

          <div id="subsc-charlie" className="subsc-box">
            <div className="subsc-line-overlay"></div>
            <h1 className="body-title">Charlie</h1>
            <div className="full-width-line">
              <div className="subsc-line-overlay-inner"></div>
            </div>
            <div className="spacing-divider--extra-small"></div>
            <h2>Monthly Access</h2>
            <p>
              <br></br>
            </p>
            <p>Access to any 'Charlie' class in development products.</p>
            <p>200 RailGuns.</p>
            <p>30 CaseFiles.</p>
            <p>2 GateCrashes.</p>
          </div>
        </div>
      </div>

      {/* SECTION - FORM */}
      <div id="ui-body-4" className="ui-body-container centre home">
        <div id="contact-scroll" className="scroll-point"></div>
        <h1 id="body-title-3" className="body-title scroll-fade">
          Request a Demo
        </h1>
        <div className="spacing-divider--small"></div>

        <form
          action="https://formspree.io/f/xvolzajz"
          method="POST"
          className="scroll-fade form-fade"
        >
          <div>
            <h3>Name</h3>
            <label className="form-input-wrapper">
              <div className="form-line-overlay-inner"></div>
              <input name="Name" rows="1"></input>
            </label>
          </div>
          <div>
            <h3>Company</h3>
            <label className="form-input-wrapper">
              <div className="form-line-overlay-inner"></div>
              <input name="Company" rows="1"></input>
            </label>
          </div>
          <div>
            <h3>Email</h3>
            <label className="form-input-wrapper">
              <div className="form-line-overlay-inner"></div>
              <input type="email" name="_replyto"></input>
            </label>
          </div>

          <div className="button-container">
            <a>
              <button type="submit" className="button pointer">
                <div className="button-strike"></div>
                <h6 className="button-text">SUBMIT</h6>
              </button>
            </a>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default index

export const Head = () => <Seo />
