import React, { Component } from "react"

export default class NodeBlock extends Component {
  constructor(props) {
    super(props)
    this.nodeBlock = null
    this.nodeLine = null
    this.html1 = null
    this.html2 = null
  }

  block1() {
    return (
      <div className={this.nodeBlock}>
        <div className="node-line">
          <div id={this.nodeLine} className="line-overlay"></div>
        </div>
        <a href="#">
          <div className="node-circle-large">
            <div className="inner"></div>
            {/*get_template_part('template-parts/circ-path');*/}
          </div>
        </a>
      </div>
    )
  }

  block2() {
    return (
      <div className={this.nodeBlock}>
        <div className="node-line">
          <div id={this.nodeLine} className="line-overlay"></div>
        </div>
        <div className="node-circle-small">
          {/*get_template_part('template-parts/circ-path');*/}
        </div>
      </div>
    )
  }
}
