import React, { Component } from "react"

import * as PIXI from "pixi.js"
import $ from "jquery"

import HeaderNode from "./header-node"
import Collision from "./collision"

export default class PixiHeader extends Component {
  constructor(props) {
    super(props)

    if (typeof document !== `undefined`) {
      this.pathName = window.location.pathname

      if (
        !this.pathName.includes("about") &&
        !this.pathName.includes("services")
      ) {
        this.resizeTimer = null
        this.resizeReboot = false

        this.borderSize = null
        this.capWidth = null

        this.centrePoint = []

        /* The nodes arrays will contain custom node objects. */
        /* These custom objects act as a wrapper for/extend the Pixi classes to contain a ref number/ additional tagging info. */
        this.nodesL2 = []
        this.collL2 = [] /* Collisions */
        this.nodesL3 = []
        this.collL3 = [] /* Collisions */

        this.pixiDiv = null
        this.runPIXI = null
        this.imgURL = null
        this.app = null

        this.run = true
        this.isSetup = null
        this.isMobile = null
      }
    }
  }

  componentDidMount() {
    if (this.run) {
      this.mobileCheck()
      this.mobileMove()
      this.appResize()

      this.refresh = window.setInterval(() => {
        this.centrePoint = []
        this.pixiDiv = document.getElementById("pixi-container")
        this.clearStage()
        this.isSetup = false
        this.nodesL2 = []
        this.nodesL3 = []
        this.appSetup()
      }, 25000)
    }
  }

  pixiApp() {
    $(document).ready(() => {
      this.pixiDiv = document.getElementById("pixi-container")
      this.runPIXI = parseInt($("#runPIXI").text())
      this.imgURL = $("#imgURL").text()

      this.capWidth = this.clamp(this.pixiDiv.offsetWidth, 0, 1680)

      if (this.runPIXI) {
        /* The PixiJS application class acts as a 'convinience class' comprised of the sub-architecture components: renderer, ticker and root container. */
        /* The PixiJS application class accepts the same arguments as the renderer. */
        this.app = new PIXI.Application({
          width: this.capWidth,
          height: this.pixiDiv.offsetHeight,
          autoResize: true,
          resizeTo: this.pixiDiv,
          resolution: window.devicePixelRatio,
          autoDensity: true,
          antialias: true,
          backgroundColor: 0x000000,
        })

        if (!this.isSetup) this.appSetup()
        if (this.isSetup) this.appDraw()

        this.pixiDiv.appendChild(this.app.view)
      }
    })
  }

  appSetup() {
    this.capWidth = this.clamp(this.pixiDiv.offsetWidth, 0, 1680)

    if ($(window).width() > 1680) {
      this.borderSize = ($(window).width() - 1680) / 2
      this.centrePoint.push(
        this.borderSize + (this.capWidth / 2 + this.capWidth / 6)
      )
    } else {
      this.centrePoint.push(this.capWidth / 2 + this.capWidth / 6)
    }
    this.centrePoint.push(
      this.pixiDiv.offsetHeight / 2 + this.pixiDiv.offsetHeight / 18
    )

    let centreSize = Math.ceil(this.capWidth / 22.5)

    /* Circle: Layer 3 */
    let pixiNodesL3 = new PIXI.Container()
    let pixiLinesL3 = new PIXI.Container()
    let angle = this.toRadians(0)
    let counter = 0
    let totalNodesL3 = 60
    for (let i = 0; i < totalNodesL3; i++) {
      let angleUpdate = this.map(i, 0, totalNodesL3, 0, 360)
      let amp = Math.ceil(this.capWidth / 3.35)
      let centreFloat = (centreSize * 2) / amp
      let tempPos = this.randomFloatFromInterval(centreFloat, 1)
      let ampPos = tempPos * amp

      let xVal = ampPos * Math.cos(angle)
      let yVal = ampPos * Math.sin(angle)

      /*                         (type, refNum,  xPos, yPos, velX, velY, centrePoint,      numLines) */
      let nodeL3 = new HeaderNode(
        "L3",
        counter,
        xVal,
        yVal,
        0,
        0,
        this.centrePoint,
        null
      )
      /* Generate initial velocity (base on size category). */
      nodeL3.velX = this.randomFloatFromInterval(-0.25, 0.25)
      nodeL3.velY = this.randomFloatFromInterval(-0.25, 0.25)

      nodeL3.setup()
      /* Ensure no objects are rendered off canvas. */
      /*              (node,   centrePoint,      offsetWidth,              offsetHeight,              centreFloat, amp, angle, Node Array,   Array Length,        xOffset). */
      this.renderSpace(
        nodeL3,
        this.centrePoint,
        this.capWidth,
        this.pixiDiv.offsetHeight,
        centreFloat,
        amp,
        angle,
        this.nodesL3,
        this.nodesL3.length,
        this.capWidth / 2.75
      )
      nodeL3.draw(null, null, true)
      pixiNodesL3.addChild(nodeL3.pixiObject)

      /* Adding the Pixi defined nodes and lines to the stage. */
      /* Thes are components of the custom defined node object and run in parallel. */
      pixiNodesL3.addChild(nodeL3.pixiObject)
      for (let i = 0; i < nodeL3.pixiLine.length; i++) {
        pixiLinesL3.addChild(nodeL3.pixiLine[i])
      }

      /* The nodes array contains the custom node objects. */
      /* These custom objects act as a wrapper for/extend the Pixi classes to contain a ref number/ additional tagging info. */
      this.nodesL3.push(nodeL3)

      angle = this.toRadians(angleUpdate)
      counter++
    }

    this.app.stage.addChild(pixiNodesL3)
    this.app.stage.addChild(pixiLinesL3)

    /* Circle: Layer 2 */
    let pixiNodesL2 = new PIXI.Container()
    let pixiLinesL2 = new PIXI.Container()
    angle = this.toRadians(0)
    counter = 0
    let totalNodesL2 = 20
    for (let i = 0; i < totalNodesL2; i++) {
      let angleUpdate = this.map(i, 0, totalNodesL2, 0, 360)
      let amp = Math.ceil(this.capWidth / 2.8)
      /* INCREASE THE AMP VALUE AS IT GOES */
      let centreFloat = (centreSize * 2) / amp
      let tempPos = this.randomFloatFromInterval(centreFloat, 1)
      let ampPos = tempPos * amp

      let xVal = ampPos * Math.cos(angle)
      let yVal = ampPos * Math.sin(angle)

      /*                         (type, refNum,  xPos, yPos, velX, velY, centrePoint,      numLines) */
      let nodeL2 = new HeaderNode(
        "L2",
        counter,
        xVal,
        yVal,
        0,
        0,
        this.centrePoint,
        5
      )
      let temp = [...this.nodesL3]
      /* Generate initial velocity (base on size category). */
      nodeL2.velX = this.randomFloatFromInterval(-0.05, 0.05)
      nodeL2.velY = this.randomFloatFromInterval(-0.05, 0.05)

      nodeL2.setup()
      /* Ensure no objects are rendered off canvas. */
      /*              (node,   centrePoint,      offsetWidth,              offsetHeight,              centreFloat, amp, angle, Node Array,   Array Length,        xOffset). */
      this.renderSpace(
        nodeL2,
        this.centrePoint,
        this.capWidth,
        this.pixiDiv.offsetHeight,
        centreFloat,
        amp,
        angle,
        this.nodesL2,
        this.nodesL2.length,
        this.capWidth / 2.75
      )
      nodeL2.draw(temp, i, true)

      /* Adding the Pixi defined nodes and lines to the stage. */
      /* Thes are components of the custom defined node object and run in parallel. */
      pixiNodesL2.addChild(nodeL2.pixiObject)
      for (let i = 0; i < nodeL2.pixiLine.length; i++) {
        pixiLinesL2.addChild(nodeL2.pixiLine[i])
      }

      /* The nodes array contains the custom node objects. */
      /* These custom objects act as a wrapper for/extend the Pixi classes to contain a ref number/ additional tagging info. */
      this.nodesL2.push(nodeL2)

      angle = this.toRadians(angleUpdate)
      counter++
    }
    this.app.stage.addChild(pixiNodesL2)
    this.app.stage.addChild(pixiLinesL2)

    /* Circle: Layer 1 */
    let pixiNodesL1 = new PIXI.Container()
    let tempCirc1 = new PIXI.Graphics()
    tempCirc1.beginFill(0x000000)
    tempCirc1.lineStyle({
      color: 0xffffff,
      width: 5,
      alignment: 0 /* 0.5 = middle, 1 = outer, 0 = inner */,
    })
    tempCirc1.drawCircle(0, 0, centreSize) // x, y, radius
    tempCirc1.endFill()
    tempCirc1.position.set(this.centrePoint[0], this.centrePoint[1])
    pixiNodesL1.addChild(tempCirc1)
    this.app.stage.addChild(pixiNodesL1)

    /* Add to layer 2 and 3 for collision detection. */
    /*                          (type, refNum,              xPos, yPos, velX, velY, centrePoint,      numLines) */
    let node1L3 = new HeaderNode(
      "L1",
      this.nodesL3.length,
      0,
      0,
      0,
      0,
      this.centrePoint,
      null
    ) /* centrePoint refers to canvas. */
    let node1L2 = new HeaderNode(
      "L1",
      this.nodesL2.length,
      0,
      0,
      0,
      0,
      this.centrePoint,
      null
    )
    node1L3.nodeSize = centreSize
    node1L2.nodeSize = centreSize
    node1L3.rad = node1L3.nodeSize / 2.0
    node1L2.rad = node1L2.nodeSize / 2.0

    /* Add L1 node for collision detection. */
    this.nodesL3.push(node1L3)
    this.nodesL2.push(node1L2)

    let tempWidth = window.innerWidth
    let tempHeight = window.innerHeight

    /* Colour Overlay/ Graident Overlay */
    let overlay = new PIXI.Graphics()
    overlay.beginTextureFill({ texture: this.gradient("#00e9e4", "#fd73fa") })
    overlay.drawRect(
      0,
      0,
      this.pixiDiv.offsetWidth,
      this.pixiDiv.offsetHeight
    ) /* x, y, radius */
    overlay.endFill()
    overlay.blendMode = PIXI.BLEND_MODES.MULTIPLY
    this.app.stage.addChild(overlay)

    this.app.stage.setChildIndex(pixiNodesL1, 4)
    this.app.stage.setChildIndex(pixiNodesL2, 3)
    this.app.stage.setChildIndex(pixiNodesL3, 2)
    this.app.stage.setChildIndex(pixiLinesL2, 1)
    this.app.stage.setChildIndex(pixiLinesL3, 0)

    this.isSetup = true
  }

  appDraw() {
    /* 1000/40 = 25 frames per second. */
    let g_TICK = 40
    let g_Time = 0

    /* Manages the minimum amount of milliseconds required to elapse between invoking PIXI.Ticker#update. */
    /* 1000/40 = 25 frames per second. */
    this.app.ticker.maxFPS = 40

    this.app.ticker.add(delta => {
      /* Cap frame frate (failsafe) - listen for animation update. */
      var timeNow = new Date().getTime()
      var timeDiff = timeNow - g_Time
      if (timeDiff < g_TICK) return

      g_Time = timeNow
      /* Cap frame rate (failsafe) end. */

      /* -1 to account for the extra L1 node. */
      for (let i = 0; i < this.nodesL3.length - 1; i++) {
        this.nodesL3[i].updatePos()
        this.nodesL3[i].draw(null, null, false)
      }

      let temp = [...this.nodesL3]
      /* -1 to account for the extra L1 node. */
      for (let i = 0; i < this.nodesL2.length - 1; i++) {
        this.nodesL2[i].updatePos()
        this.nodesL2[i].draw(temp, i, false)
      }

      /* Keep nodeL1 in the centre. */
      this.nodesL3[this.nodesL3.length - 1].xPos = 0
      this.nodesL3[this.nodesL3.length - 1].yPos = 0
      this.nodesL2[this.nodesL2.length - 1].xPos = 0
      this.nodesL2[this.nodesL2.length - 1].yPos = 0
      this.nodesL3[this.nodesL3.length - 1].velX = 0
      this.nodesL3[this.nodesL3.length - 1].velY = 0
      this.nodesL2[this.nodesL2.length - 1].velX = 0
      this.nodesL2[this.nodesL2.length - 1].velY = 0

      /* Collision detection - via function. */
      let collL3 = this.getCollV1(this.nodesL3, this.nodesL3.length)
      let collL2 = this.getCollV1(this.nodesL2, this.nodesL2.length)

      for (let kL3 = 0; kL3 < collL3.length; kL3++)
        this.setNewVelocities(this.nodesL3, collL3[kL3], -0.5, 0.5) // -2.5, 2.5
      for (let kL2 = 0; kL2 < collL2.length; kL2++)
        this.setNewVelocities(this.nodesL2, collL2[kL2], -0.1, 0.1)

      /* Wall collision detection - via function. */
      this.canvasEdge(this.nodesL3, this.nodesL3.length, this.capWidth / 3.25)
      this.canvasEdge(this.nodesL2, this.nodesL2.length, this.capWidth / 3.25)
    })
  }

  appResize() {
    $(window).resize(() => {
      if (this.isSetup) {
        this.pixiDiv = document.getElementById("pixi-container")
        this.app.renderer.resize()
      }

      this.clearStage()

      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.afterResize()
      }, 2500)
    })
  }

  afterResize() {
    this.centrePoint = []
    this.pixiDiv = document.getElementById("pixi-container")
    this.clearStage()
    this.isSetup = false
    this.nodesL2 = []
    this.nodesL3 = []
    this.appSetup()
  }

  clearStage() {
    if (this.run) {
      while (this.app.stage.children[0]) {
        this.app.stage.removeChild(this.app.stage.children[0])
      }
    }
  }

  randomIntFromInterval(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  randomFloatFromInterval(min, max) {
    return Math.random() * (max - min) + min
  }

  map(n, start1, stop1, start2, stop2) {
    return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
  }

  toDegrees(angle) {
    return angle * (180 / Math.PI)
  }

  toRadians(angle) {
    return angle * (Math.PI / 180)
  }

  dist(x1, y1, x2, y2) {
    return Math.hypot(x2 - x1, y2 - y1)
  }

  gradient(from, to) {
    const c = document.createElement("canvas")
    c.width = this.capWidth
    c.height = this.pixiDiv.offsetHeight
    const ctx = c.getContext("2d")
    const grd = ctx.createLinearGradient(
      0,
      0,
      this.capWidth,
      this.pixiDiv.offsetHeight
    )

    grd.addColorStop(0, from)
    grd.addColorStop(1, to)
    ctx.fillStyle = grd
    ctx.fillRect(0, 0, this.capWidth, this.pixiDiv.offsetHeight)

    return new PIXI.Texture.from(c)
  }

  /* Search each combination - check collision dist. */
  getCollV1(nodes, nodesLength) {
    let collArr = [] /* Empty collisions array. */
    /* For each node - check every other. */
    for (let i = 0; i < nodesLength; i++) {
      for (let j = i + 1; j < nodesLength; j++) {
        /* Using the radius - check what the distance of the node centre points would be on collision. */
        /* Square the result (to match the format of the calculation below). */
        let colDist = Math.pow(nodes[i].nodeSize + nodes[j].nodeSize, 2)

        /* Find the x and y dist between the 2 nodes. */
        /* Square the result. (this is so the resulting value always returns positive). */
        let nodeDistSq =
          Math.pow(nodes[i].xPos - nodes[j].xPos, 2) +
          Math.pow(nodes[i].yPos - nodes[j].yPos, 2)

        /* If the real-time dist value matches the collision dist - push collision to array. */
        /* The collision array contains the index values of node 1 and 2. */
        if (nodeDistSq <= colDist) {
          collArr.push(new Collision(i, j))
        }
      }
    }
    return collArr
  }

  getCollV2(node, nodes, nodesLength) {
    for (let i = 0; i < nodesLength; i++) {
      let colDist = Math.pow(node.nodeSize + nodes[i].nodeSize, 2)
      let nodeDistSq =
        Math.pow(node.xPos - nodes[i].xPos, 2) +
        Math.pow(node.yPos - nodes[i].yPos, 2)
      if (nodeDistSq <= colDist) {
        return true
      }
    }

    return false
  }

  /* Calculate new velocity for each element on impact. */
  setNewVelocities(nodeType, collNum, clampA, clampB) {
    /* Retrieve ball objects specific to collision. */
    let nodeA = nodeType[collNum.node1]
    let nodeB = nodeType[collNum.node2]

    let fakebVX = nodeB.velX
    let fakebVY = nodeB.velY

    /* Just results as 0? */
    nodeB.velX -= fakebVX
    nodeB.velY -= fakebVY
    nodeA.velX -= fakebVX
    nodeA.velY -= fakebVY

    let angleI = Math.abs(Math.atan(nodeA.velY / nodeA.velX))
    if (nodeA.velY < 0) angleI *= -1

    let distX = nodeB.xPos - nodeA.xPos
    let distY = nodeB.yPos - nodeA.yPos
    let tempX = distX
    let tempY = distY

    distX = tempX * Math.cos(-angleI) - tempY * Math.sin(-angleI)
    distY = tempX * Math.sin(-angleI) + tempY * Math.cos(-angleI)

    let angleB = Math.abs(Math.atan(distY / distX))
    if (distY < 0) angleB *= -1
    let angleA = Math.PI / 2 - Math.abs(angleB)
    if (angleB > 0) angleA *= -1

    let vai = Math.sqrt(Math.pow(nodeA.velX, 2) + Math.pow(nodeA.velY, 2))
    let vaf = vai * Math.cos(angleA)
    let vbf = vai * Math.cos(angleB)

    nodeA.velX = vaf * Math.cos(angleA)
    nodeA.velY = vaf * Math.sin(angleA)
    nodeB.velX = vbf * Math.cos(angleB)
    nodeB.velY = vbf * Math.sin(angleB)

    let tempaVX = nodeA.velX
    let tempaVY = nodeA.velY
    let tempbVX = nodeB.velX
    let tempbVY = nodeB.velY

    nodeA.velX = tempaVX * Math.cos(angleI) - tempaVY * Math.sin(angleI)
    nodeA.velY = tempaVX * Math.sin(angleI) + tempaVY * Math.cos(angleI)
    nodeB.velX = tempbVX * Math.cos(angleI) - tempbVY * Math.sin(angleI)
    nodeB.velY = tempbVX * Math.sin(angleI) + tempbVY * Math.cos(angleI)

    nodeB.velX += fakebVX
    nodeB.velY += fakebVY
    nodeA.velX += fakebVX
    nodeA.velY += fakebVY

    nodeB.velX = this.clamp(nodeB.velX, clampA, clampB)
    nodeB.velY = this.clamp(nodeB.velY, clampA, clampB)
    nodeA.velX = this.clamp(nodeA.velX, clampA, clampB)
    nodeA.velY = this.clamp(nodeA.velY, clampA, clampB)
  }

  /* Wall collision detection. */
  canvasEdge(nodes, nodesLength, xOffset) {
    for (let i = 0; i < nodesLength; i++) {
      if (
        nodes[i].xPos < 0 - this.centrePoint[0] + xOffset + nodes[i].nodeSize ||
        nodes[i].xPos >
          0 + (this.capWidth - this.centrePoint[0]) - nodes[i].nodeSize
      )
        nodes[i].velX *= -1
      if (
        nodes[i].yPos < 0 - this.centrePoint[1] + 45 + nodes[i].nodeSize ||
        nodes[i].yPos >
          -45 +
            (this.pixiDiv.offsetHeight - this.centrePoint[1]) -
            nodes[i].nodeSize
      )
        nodes[i].velY *= -1
    }
  }

  renderSpace(
    node,
    centrePoint,
    offsetWidth,
    offsetHeight,
    centreFloat,
    amp,
    angle,
    nodeArray,
    arrayLength,
    xOffset
  ) {
    while (this.getCollV2(node, nodeArray, arrayLength)) {
      node.xPos =
        this.randomFloatFromInterval(centreFloat, 1) * amp * Math.cos(angle)
      node.yPos =
        this.randomFloatFromInterval(centreFloat, 1) * amp * Math.sin(angle)
    }
  }

  clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num
  }

  appStop(currApp) {
    currApp.stop()
  }

  appStart(currApp) {
    currApp.start()
  }

  mobileCheck() {
    $(document).ready(() => {
      if ($(window).width() < 999) {
        this.isMobile = true
      } else {
        this.isMobile = false
        if (this.run) {
          this.pixiApp()
        }
      }
    })
  }

  mobileMove() {
    $(window).resize(() => {
      /*
      if ($(window).width() < 999 && !this.isMobile) {
        this.isMobile = true;
        if (this.isSetup) {
          this.appStop(this.app);
        }
      } else if ($(window).width() > 999 && this.isMobile && this.run) {
        this.isMobile = false;
        if (!this.isSetup) {
          this.pixiApp();
        } else {
          this.appStart(this.app);
        }
        //this.appStart(this.app);
        // RESTART.
      }
      */
    })
  }
}
