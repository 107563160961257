import React, { Component } from "react"

import * as PIXI from "pixi.js"
import $ from "jquery"

export default class Collision {
  constructor(node1, node2) {
    this.node1 = node1
    this.node2 = node2
  }
}
